require('../scss/pages/index.scss');
require('slick-carousel/slick/slick');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');
require('bootstrap/js/dist/modal');

const $ = require('jquery');

$(document).ready(() => {
	const main = {
		backToTop: (window, elm) => {
			window.scrollTop() > 100 ? elm.fadeIn() : elm.fadeOut();
		},
		scrollNav: function(_options) {
			const options = $.extend(
				{
					nav: '.js-mainMenu a',
					animSpeed: 750,
				},
				_options,
			);
			const window = $('html,body');
			const addingHeight = $('.js-mainMenu').outerHeight(true) + 50;

			const nav = $(options.nav);
			nav.each(function() {
				const hold = $(this);
				const block = $(hold.attr('href'));
				if (block.length) {
					hold.click(function() {
						const blockPost = block.offset().top - addingHeight;

						window
							.stop()
							.animate(
								{ scrollTop: blockPost },
								{ duration: options.animSpeed },
							);
						return false;
					});
				}
			});
		},
	};

	main.scrollNav({ nav: '.js-mainMenu a' });

	const backToTop = $('.js-backToTop');
	main.backToTop($(window), backToTop);

	backToTop.on('click', function() {
		$('html,body').animate({ scrollTop: 0 }, 800);
	});

	const head_nav = $('.js-headNav');
	const offset_top = head_nav.outerHeight();

	$(window).on('scroll', function() {
		const w = $(this);

		if (w.scrollTop() > offset_top) {
			head_nav.addClass('scroll');
		} else {
			head_nav.removeClass('scroll');
		}

		main.backToTop(w, backToTop);
	});

	$('.js-sliderServices').slick({
		infinite: true,
		dots: true,
		autoplay: true,
		autoplaySpeed: 5000,
	});

	$('.js-sliderPartner').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 386,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	});

	$('.js-sliderPromo').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	$('.js-showModalPromo').on('click', e => {
		const me = e.currentTarget;
		const imgUrl = $(me)
			.find('img')
			.attr('src');
		const imgPromo = $('.js-imgPromo');
		imgPromo.attr('src', imgUrl);
		$('#modalPromo').modal('show');
	});
});
